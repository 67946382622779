<template>
  <div>
    <!-- Title -->
    <b-card-title class="text-primary mb-3">Reset Password</b-card-title>

    <!-- Alert -->
    <b-alert variant="info" dismissible show fade>
      An OTP has been sent to your registered mobile number. Please enter the
      OTP and a new password to reset your credentials.
    </b-alert>

    <reset-password :otpData="otpData" @success="successReset" />

    <!-- Success Modal -->
    <b-modal
      title="Success"
      v-model="showSuccessAlert"
      size="sm"
      hide-footer
      no-close-on-backdrop
      centered
      @close="onSuccessAlertClose"
    >
      Password reset successful.
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { authenticate } from "../../apis";
import ResetPassword from "../../components/auth/ResetPassword.vue";

export default {
  components: { ResetPassword },
  created() {
    if (!this.otpData) {
      // no otp data found, return to login
      this.$router.replace({ name: "Home" });
      return;
    }
  },
  data() {
    return {
      query: this.$route.query,
      params: this.$route.params,

      showSuccessAlert: false,
      passwordSet: null,
    };
  },
  computed: {
    otpData() {
      return this.params.otpData;
    },
    mobile() {
      return this.params.mobile;
    },
  },
  methods: {
    ...mapActions({
      saveToken: "login/saveToken",
    }),
    successReset(data) {
      this.passwordSet = data.password;
      this.showSuccessAlert = true;
    },
    onSuccessAlertClose() {
      const mobile = this.mobile;
      const password = this.passwordSet;
      // directly perform login, with mobile number and new password
      authenticate({ mobile, password }).then((data) => {
        this.saveToken(data.token).then(() => {
          let redirect = this.query.redirect;
          if (!redirect) redirect = "Home";
          this.$router.replace({
            name: redirect,
            params: { ...this.params, ...this.query },
            query: this.query,
          });
          return;
        });
      });
    },
  },
};
</script>
